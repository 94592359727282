import { gql } from '@apollo/client';
import { GetEsignClientIdResponse } from '@endpoint/endpoint-bff-graphql-schema';

export interface GetEsignClientIdResult {
  getEsignClientId: GetEsignClientIdResponse;
}

export const GET_ESIGN_CLIENT_ID = gql`
  query GetEsignClientId($input: GetEsignClientIdInput!) {
    getEsignClientId(input: $input) {
      esignClientId
    }
  }
`;
