import React, { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Todo } from '@endpoint/platform-api-connector/dist/graphql-types';
import replace from 'lodash/replace';
import { clearTokens } from 'utils/auth/storage';
import { ErrorLoadingStatesWidget } from 'routes/TransactionDetail/Todo/TodoStep/TodoStepComponent/ErrorLoadingStatesWidget';
import { formatDate, MONTH_AND_DAY } from 'utils/formatDate';
import { getTodoStepUrl } from 'routes/TransactionDetail/Todo/helpers/navigation';
import { getTransactionRole } from 'utils/transactions/getTransactionRole';
import { QueryParam } from 'consts/enums';
import { renewToken } from 'utils/auth';
import { TodoButtonNavigation } from '@endpoint/endpoint-bff-graphql-schema';
import { TodoSkeleton } from 'components/Skeleton';
import { useAppContext } from 'utils/context';
import { useSubmitTodoAssignmentStep } from 'hooks/useSubmitTodoAssignmentStep';
import { useTodoStepContext } from 'routes/TransactionDetail/Todo/TodoStep';
import { useTransaction } from 'hooks/useTransaction';
import { useGetPayableToName } from 'hooks/useGetPayableName';

interface MobileCheckDepositWidgetProps {
  value: string | undefined;
}

export const MobileCheckDepositWidget: FC<MobileCheckDepositWidgetProps> = ({ value }) => {
  const [accessToken, setAccessToken] = useState<string>('');
  const navigate = useNavigate();
  const { transactionId = '', todoId = '', stepId = '' } = useParams();
  const { user } = useAppContext();
  const [searchParams] = useSearchParams();
  const { submitTodoAssignmentStep } = useSubmitTodoAssignmentStep();
  const { data: transactionData, error: transactionError, loading: transactionLoading } = useTransaction(transactionId);
  const { todoAssignment, getTodoAssignment } = useTodoStepContext();
  const redirect = searchParams.get('redirect') === QueryParam.PAYMENT_METHOD_OPTIONS;

  const { transaction } = { ...transactionData };
  const failureReturnRoute = `/transaction/${transactionId}/todo/${todoId}/step/${stepId}?redirect=${
    QueryParam.PAYMENT_METHOD_OPTIONS
  }&t=${Date.now()}`;
  const returnRoute = `/transaction/${transactionId}/todos`;
  const mobileCheckTodo =
    transaction?.todos.filter((todo: Todo) => todo?.assignments && todo.assignments[0]?.id === todoId) || [];
  const dueDate: string | null = (mobileCheckTodo && mobileCheckTodo[0]?.due) || null;
  const fileNumber: string | null = (transaction && transaction.fileNum) || null;
  const firstName: string | null = (user && user.firstName) || null;
  const lastName: string | null = (user && user.lastName) || null;
  const groupId: string | null = (mobileCheckTodo && mobileCheckTodo[0]?.id) || null;
  const note: string | null = (mobileCheckTodo && mobileCheckTodo[0]?.escrowNote) || null;
  const todoName: string | null = (mobileCheckTodo && mobileCheckTodo[0]?.name) || null;
  const todoStatus: string | null = (mobileCheckTodo && mobileCheckTodo[0]?.status) || null;
  const userId: string | null = (user && user.id) || null;
  const expectedUserRole: string = transaction
    ? getTransactionRole({ transaction, userId: user.id }).label
    : 'Not Available';

  const formattedPaymentAmount: number = value && value.length ? Number(replace(value, ',', '')) : 0;
  const { payableTo, payableToNameError, payableToNameLoading } = useGetPayableToName({
    transactionId,
    marketId: transaction?.marketId || undefined,
    isUnityTransaction: true,
  });

  useEffect(() => {
    const clearSession = async () => {
      await clearTokens();
      navigate('/signin');
    };

    renewToken()
      .then(async (token: string) => {
        token ? setAccessToken(token) : await clearSession();
      })
      .catch(async () => clearSession());
  }, [navigate]);

  const mobileCheckPayload = useMemo(
    () => ({
      event: 'MOBILE_CHECK_DEPOSIT_WIDGET',
      payload: {
        accessToken,
        failureReturnRoute,
        returnRoute,
        transactionId,
        taskId: todoId,
        fileNumber,
        groupId,
        todoName,
        todoStatus,
        userId,
        userRole: expectedUserRole,
        firstName,
        lastName,
        dueDate: dueDate ? formatDate(dueDate, MONTH_AND_DAY, true) : null,
        transactionAddress: {
          street1: transaction?.property.address.street1,
          street2: transaction?.property.address.street2,
          city: transaction?.property.address.city,
          state: transaction?.property.address.state,
          zip: transaction?.property.address.zip,
          county: transaction?.property.address.county,
        },
        note,
        checkInstructions: {
          amount: formattedPaymentAmount,
          payableTo,
        },
      },
    }),
    [
      accessToken,
      failureReturnRoute,
      returnRoute,
      transactionId,
      todoId,
      fileNumber,
      groupId,
      todoName,
      todoStatus,
      userId,
      expectedUserRole,
      firstName,
      lastName,
      dueDate,
      formattedPaymentAmount,
      payableTo,
      transaction,
      note,
    ],
  );

  useEffect(() => {
    const redirectToPreviousStep = async () => {
      const response = await submitTodoAssignmentStep({
        variables: {
          input: {
            todoAssignmentId: todoId,
            currentStepId: stepId,
            direction: TodoButtonNavigation.PREVIOUS,
            hasSuccessPage: todoAssignment.hasSuccessPage,
            hasSummaryPage: todoAssignment.hasSummaryPage,
          },
        },
      });

      const previousStepId = response.data?.submitTodoAssignmentStep?.todoAssignmentStepId;

      if (previousStepId) {
        const stepUrl = getTodoStepUrl({ transactionId, todoId, stepId: previousStepId });

        navigate(stepUrl);
        getTodoAssignment({ variables: { todoAssignmentId: todoId, stepId: previousStepId } });
      }
    };

    if (accessToken) {
      if (redirect) {
        redirectToPreviousStep();
      } else if (!transactionError && !transactionLoading) {
        window?.ReactNativeWebView?.postMessage(JSON.stringify(mobileCheckPayload));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, redirect, transactionError, transactionLoading]);

  if (transactionError || transactionLoading || payableToNameError || payableToNameLoading) {
    return (
      <ErrorLoadingStatesWidget
        error={transactionError || payableToNameError}
        loading={transactionLoading || payableToNameLoading || !accessToken}
      />
    );
  }

  return <TodoSkeleton data-test-id="skeleton" />;
};
