import React, { Fragment, FC, useCallback, useState, useEffect } from 'react';
import { Box, Flex, Stack, Text, Icon } from '@endpoint/blockparty';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import { omit } from 'lodash';
import { Document } from '@endpoint/endpoint-bff-graphql-schema';
import { fetchHelper } from 'consts/api';

import { getUniqueId } from '../../utils/getUniqueId';
import { S3MetaData } from '../../routes/TransactionDetail/Documents/interfaces';
import { useCreateDocument } from '../../hooks/useCreateDocument';
import { Uploading } from './Uploading';
import { UploadFailed } from './UploadFailed';
import { UploadSuccess } from './UploadSuccess';
import { useCreateTodoDocument } from '../../hooks/useCreateTodoDocument';

/* ====================================== */

const MAX_UPLOAD_SIZE = 50000000;
const MIME_TYPES = ['application/pdf', 'image/jpeg'];

export enum UploadStatus {
  LOADING = 'loading',
  FAILED = 'failed',
  SUCCESS = 'success',
  DEFAULT = 'default',
}

export enum FileRejections {
  FILE_INVALID_TYPE = 'file-invalid-type',
  FILE_TOO_LARGE = 'file-too-large',
}

interface UploadProps extends DropzoneOptions {
  todoAssignmentId?: string;
  multiple?: boolean;
  maxSize?: number;
  accept?: Array<string>;
  callback: Function;
  files?: UploadedFiles;
  documentType?: string;
  useGrayUploadSuccess?: boolean;
}
export interface UploadedFileProps {
  id: string;
  name: string;
  file?: Partial<File>;
  percentage?: number;
  status?: UploadStatus;
  cancel?: XMLHttpRequest['abort'];
  close?: () => void;
  response?: UploadResponse[];
  uploadUrl?: string;
}

export interface UploadResponse {
  filename: string;
  mime_type: string;
  s3?: S3MetaData;
}

export interface UploadedFiles {
  [key: string]: UploadedFileProps;
}

export interface CreateUnassignedDocumentResponse {
  createUnassignedDocument: Document;
}
interface CreateTodoDocumentResponse {
  createTodoDocument: Document;
}

/*= ====================================== */

export const Upload: FC<UploadProps> = ({
  maxSize = MAX_UPLOAD_SIZE,
  accept = MIME_TYPES,
  multiple = true,
  callback,
  files,
  useGrayUploadSuccess = false,
  documentType = 'Other',
  todoAssignmentId = '',
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFiles>(files || {});
  const [documentForUpload, setDocumentForUpload] = useState<UploadedFileProps>({} as UploadedFileProps);
  const { createUnassignedDocument } = useCreateDocument({
    errorPolicy: 'all',
    onCompleted: (data: CreateUnassignedDocumentResponse) => {
      addSignedUrl(data.createUnassignedDocument);
    },
  });

  const { createTodoDocument } = useCreateTodoDocument({
    errorPolicy: 'all',
    onCompleted: (data: CreateTodoDocumentResponse) => {
      addSignedUrl(data.createTodoDocument);
    },
  });

  useEffect(() => {
    callback && callback(uploadedFiles);
    Object.entries(uploadedFiles).forEach(([key, value]) => {
      uploadedFiles[key] = { ...value, close: setDefaultStatus(value.name) };
    });
  }, [callback, uploadedFiles]);

  useEffect(() => {
    const { file, uploadUrl } = documentForUpload;
    const name = file?.name;

    if (uploadUrl && name) {
      const config = {
        method: 'PUT',
        data: file,
        transformRequest: [
          (data: any, headers: any) => {
            delete headers.common.Authorization; // eslint-disable-line no-param-reassign
            headers['Content-Type'] = file?.type; // eslint-disable-line no-param-reassign

            return data;
          },
        ],
        onUploadProgress: (e: any) => {
          const percentage = Math.round((e.loaded * 95) / e.total);

          setUploadedFiles((prev) => ({
            ...prev,
            ...(prev[name] && {
              [name]: {
                ...prev[name],
                file,
                percentage,
              },
            }),
          }));
        },
      };

      fetchHelper(uploadUrl, config, false)
        .then(() => {
          setUploadedFiles((prev) => ({
            ...prev,
            ...(prev[name] && {
              [name]: {
                ...prev[name],
                file,
                percentage: 100,
                status: UploadStatus.SUCCESS,
              },
            }),
          }));
        })
        .catch(() => {
          setUploadedFiles((prev) => ({
            ...prev,
            ...(prev[name] && {
              [name]: {
                ...prev[name],
                file,
                percentage: 0,
                status: UploadStatus.FAILED,
              },
            }),
          }));
        });
    }
  }, [documentForUpload]);

  const setDefaultStatus = (id: string) => () => {
    setUploadedFiles((prev) => omit(prev, id));
  };

  const addSignedUrl = (document: Document) => {
    const fileStateTmp = uploadedFiles;

    fileStateTmp[document.name].uploadUrl = document.uploadUrl;
    fileStateTmp[document.name].id = document.id;

    setUploadedFiles((prev) => ({
      ...prev,
      ...fileStateTmp,
    }));

    setDocumentForUpload(fileStateTmp[document.name]);
  };

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const fileWithIds = acceptedFiles.map((x) => ({ id: getUniqueId(), file: x, name: x.name }));

      const fileState = fileWithIds.reduce((acc: UploadedFiles, file) => {
        acc[file.name] = {
          ...file,
          name: file.name,
          percentage: 0,
          status: UploadStatus.LOADING,
          close: setDefaultStatus(file.name),
        };

        return acc;
      }, {} as UploadedFiles);

      setUploadedFiles((prev) => ({
        ...prev,
        ...fileState,
      }));

      const bffCalls: Promise<void>[] = [];

      let input;

      acceptedFiles.forEach((file) => {
        if (documentType === 'Purchase Agreement') {
          input = {
            filename: file.name,
            documentType,
          };

          bffCalls.push(createUnassignedDocument(input));
        } else {
          input = {
            todoAssignmentId,
            filename: file.name,
            documentType: documentType || 'Other',
          };

          bffCalls.push(createTodoDocument(input));
        }
      });

      const allPromise = Promise.all(bffCalls);

      try {
        await allPromise;
      } catch (error) {
        setUploadedFiles((prev) => {
          const prevUploadedFiles = { ...prev };

          Object.entries(prevUploadedFiles).forEach(([key, value]) => {
            prevUploadedFiles[key] = { ...value, percentage: 0, status: UploadStatus.FAILED };
          });

          return prevUploadedFiles;
        });
      }
    },
    [documentType, createUnassignedDocument, todoAssignmentId, createTodoDocument],
  );

  // Dropzone hook
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    accept,
    maxSize,
    multiple,
    onDrop,
  });

  const toggleUploadWidget = !multiple && Object.values(uploadedFiles).length && Object.values(uploadedFiles)[0].status;

  return (
    <>
      {toggleUploadWidget ? null : (
        <Box {...getRootProps()} color="transparent" cursor="pointer" data-test-id="root-file-uploader" mb="space40">
          <Box as="input" {...getInputProps()} color="transparent" data-test-id="form-file-uploader" />
          <Flex
            alignItems="center"
            bg={isDragActive && !isDragReject ? 'blue0' : 'white'}
            border="1px dashed"
            borderColor="carbon300"
            borderRadius="4px"
            justifyContent="center"
            py="20px"
          >
            <Icon color="blue500" mr="space30" name="Upload" size="large" />
            <Text size="fontSize20">Choose a file or drag it here</Text>
          </Flex>
        </Box>
      )}

      {/* Default error message */}
      {fileRejections.length > 0 ? (
        <Text as="p" color="watermelon500" data-test-id="file-rejection" mb="space40">
          Error: {getErrorMessage(fileRejections[0]?.errors[0]?.code)}
        </Text>
      ) : null}

      <Stack>
        {Object.values(uploadedFiles).map((uploadedFile: UploadedFileProps, i) => {
          const fileId = Object.keys(uploadedFiles)[i];

          return (
            <Fragment key={fileId}>
              {uploadedFile.status === UploadStatus.LOADING && <Uploading uploadedFile={uploadedFile} />}
              {uploadedFile.status === UploadStatus.FAILED && <UploadFailed uploadedFile={uploadedFile} />}
              {uploadedFile.status === UploadStatus.SUCCESS && (
                <UploadSuccess uploadedFile={uploadedFile} useGrayUploadSuccess={useGrayUploadSuccess} />
              )}
            </Fragment>
          );
        })}
      </Stack>
    </>
  );
};

export const getErrorMessage = (errorCode: string): string => {
  switch (errorCode) {
    case FileRejections.FILE_INVALID_TYPE:
      return 'File type must be PDF or JPG';
    case FileRejections.FILE_TOO_LARGE:
      return 'File is larger than 50 MB';
    default:
      return 'File is invalid';
  }
};
