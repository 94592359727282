import { useQuery } from '@apollo/client';
import { BFF_CLIENT_NAME } from 'Apollo';
import { useAppConfigsContext } from 'utils/context';
import { getConfig } from 'utils/config';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

import { GET_ESIGN_CLIENT_ID, GetEsignClientIdResult } from './queries';

export const useEsignClientId = () => {
  const { ORGANIZATION_ID } = useAppConfigsContext();
  const { isDropboxSignClientIdFromEsignService } = useFeatureFlags();
  const skipQuery = !isDropboxSignClientIdFromEsignService;

  const { HELLOSIGN_CLIENT_ID } = getConfig();
  const { data, loading, error } = useQuery<GetEsignClientIdResult>(GET_ESIGN_CLIENT_ID, {
    variables: { input: { organizationId: ORGANIZATION_ID } },
    context: { clientName: BFF_CLIENT_NAME },
    fetchPolicy: 'cache-and-network',
    skip: skipQuery,
  });

  return {
    esignClientId: skipQuery ? HELLOSIGN_CLIENT_ID : data?.getEsignClientId.esignClientId,
    esignClientIdLoading: loading,
    esignClientIdError: error,
  };
};
