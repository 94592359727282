import { capitalize } from 'lodash';
import { useCallback, useContext, useState } from 'react';
import { updateCognitoPoolUserAttribute } from 'utils/auth/storage';
import { AppContext, AppContextType, LastTransactionViewedType, User } from 'utils/context';
import { getSupportedUSState } from 'utils/getSupportedUSState';
import * as Sentry from '@sentry/react';
import { MeResponse } from '@endpoint/endpoint-bff-graphql-schema';
import { isNewLoginSystemEnabled } from 'Auth/Utilities/helper';

export const useSetAppContextUser = () => {
  const newLoginSystemEnabled = isNewLoginSystemEnabled();
  const { setUser }: AppContextType = useContext(AppContext);
  const [lastTransactionViewed, setLastTransactionViewed] = useState<LastTransactionViewedType>({
    fileNum: '',
    marketId: undefined,
    officeName: '',
  });

  const setAppContextUser = useCallback(
    (getMeData: MeResponse) => {
      const userStateFromGetMe: User = {
        loading: !getMeData,
        id: getMeData?.id,
        firstName: getMeData?.firstName && capitalize(getMeData?.firstName),
        middleName: getMeData?.middleName && capitalize(getMeData?.middleName),
        lastName: getMeData?.lastName && capitalize(getMeData?.lastName),
        email: getMeData?.email,
        phone: getMeData?.phone,
        contactType: getMeData?.contactType,
        suffix: getMeData?.suffix,
        entityName: getMeData?.entityName,
        realEstateAgentLicenseNumber: getMeData?.licenseNumber,
        notificationPreferences: getMeData?.notificationPreferences,
        isOnboarded: getMeData?.isOnboarded ?? false,
        group: getMeData?.group,
        refetch: false,
        lastTransactionViewed,
        updateLastTransactionViewed: setLastTransactionViewed,
        stateSignedUp: getSupportedUSState(getMeData?.stateOfOperation),
        transactions: getMeData?.transactions || [],
        isAgent: getMeData.isAgent,
        isTransactionCoordinator: getMeData.isTransactionCoordinator,
      };

      if (newLoginSystemEnabled) {
        setUser((currentUserState: User) => ({
          ...currentUserState,
          ...userStateFromGetMe,
        }));
      } else {
        updateCognitoPoolUserAttribute(getMeData?.id)
          .then(() => {
            setUser(
              (currentUserState: User) => ({
                ...currentUserState,
                ...userStateFromGetMe,
              }),
              [],
            );
          })
          .catch((err) => {
            Sentry.captureEvent({
              level: Sentry.Severity.Warning,
              message: `Error trying to update userID in cognito pool. Error: ${JSON.stringify(err)}}`,
            });
          });
      }
    },
    [lastTransactionViewed, newLoginSystemEnabled, setUser],
  );

  return {
    setAppContextUser,
  };
};
